// our domain  healthclubpk.ae
export const ossBaseUrl = "http://pk.bemaxfit.com";
export const PARTNER = "digitology";
export const productId = "903496260448";
export const apiBaseUrl = "http://pk.bemaxfit.com/partners/api/v2/digitology/";
export const utmSource = "ads_digitology";
export const locale = "en";
export const postHeaders = {
  "Content-Type": "application/json",
  Authorization: "dXNyLWRpZzpwYXNzLWRpZw==",
};
export const activationUrl = `${ossBaseUrl}/store/identify`;

export const gtmId = "GTM-PH5TBSPJ";
