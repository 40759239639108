import { useEffect, useState } from "react";
import Landing from "../components/Landing";
import {
  checkFlowService,
  sendOtpService,
  subscribeUserService,
} from "../services/SubscriptionService";
import Flows from "../constants/Flows";
import { ICheckFlowTypeApi, ISubscribeApi } from "../interfaces/ApiInterfaces";
import { activationUrl } from "../constants/Config";
import { formatPrice, getErrorMessage } from "../utils/CommonFunctions";
import AppMessages from "../constants/AppMessages";

let redirectionUrl: string | null = "";
let sessionId: string | null = "";
let heMsisdn: string | null = "";
const LandingScreen = () => {
  //  This will save the flow type received in eligibility API
  // -1 is for error
  //  0 is for consent flow
  //  1 is for one click flow
  //  2 and 3 are for one two click flow
  //  4 describes that user is already subscribed,redirect him to redirection url (this will not be received in eligibility API(flow paremeter) )
  //  5 is for one OTP flow
  const [flowType, setFlowType] = useState<number>(-1);
  //  This state will save the price received from eligibility API
  const [price, setPrice] = useState<string | null>(null);
  //  This state will save the currency received from eligibility API
  const [currency, setCurrency] = useState<string | null>(null);
  //  This state will save and show the error messages that will occure in current components
  const [errorMsg, setErrorMsg] = useState<string>("");
  //  This state will show the description under Subscribe button, and it will change only when eligibility AP returns user already subscribed.
  const [description, setDescription] = useState<string>(AppMessages.consent);
  //  This state will show the text of main button. It can change depending upon flow type
  const [subscribeBtnText, setSubscribeBtnText] =
    useState<string>("Subscrbe Now");
  //  This state will save the msisdn value in input field or msisdn received from eligibility APIs
  const [msisdn, setMsisdn] = useState<string | null>("");
  // This state will save the OTP value in input field
  const [otp, setOtp] = useState<string | null>("");
  // This state will change the view from msisdn input field to OTP input fields
  const [isOtp, setIsOtp] = useState<boolean>(false);
  // This state will show the loading indicator when ever any action or API will be called
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // This state will save the payload received in eligibility API
  const [payload, setPayload] = useState<string | null>(null);

  useEffect(() => {
    getFlowType();
  }, []);

  useEffect(() => {
    // This code snippet is used to inject custom script at runtime received in eligibility API.
    // for this to work make an empty div with id "payload-container".
    if (payload) {
      // Create a temporary container for the HTML content
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = payload;

      // Append the container's content to the actual DOM
      let payloadContainer: HTMLElement | null =
        document.getElementById("payload-container");
      if (payloadContainer) {
        payloadContainer.appendChild(tempContainer);
        // Execute the script tags within the payload
        const scriptTags = tempContainer.getElementsByTagName("script");
        for (let i = 0; i < scriptTags.length; i++) {
          eval(scriptTags[i].innerHTML); // Execute the script content
        }
      }
    }
  }, [payload]);

  const getFlowType = async () => {
    // This function calls the eligibility API and decides the flow of app.
    try {
      let response: ICheckFlowTypeApi = await checkFlowService();
      console.log("status :", response.status);
      if (
        handleCheckFlowApiErrors(
          response.status,
          response.consentUrl,
          response.flow
        )
      ) {
        return; // if error exist no need to proceed further
      }

      if (response.status === 0) {
        console.log("aaa :");
        // status 0 indicates user already exist.In this case redirect user to redirect url on clicking button
        // for this case set flow type to 4 and change button text to Login
        if (response.redirectUrl) {
          redirectionUrl = response.redirectUrl;
        } else {
          redirectionUrl = activationUrl;
        }
        setFlowType(4);
        setSubscribeBtnText("Login");
        setDescription(AppMessages.alreadySubscribed);
      } else {
        checkFlowType(
          response.flow,
          response.consentUrl,
          response.redirectUrl,
          response.number
        );
      }
      setPrice(formatPrice(response.product.price));
      setCurrency(response.product.currency);
      sessionId = response.sessionId;
      setPayload(response.payload);
    } catch (error) {
      console.log("error :: ", error);
    }
  };

  const checkFlowType = (
    flowTypeValue: string | null,
    consentUrl: string | null,
    redirectUrl: string | null,
    heNumber: string | null
  ) => {
    // This functions checks the type of flow and save it in a state.
    try {
      console.log("flowTypeValue :: ", flowTypeValue);
      switch (flowTypeValue?.toLowerCase()) {
        case Flows.consent:
          console.log("1");
          setFlowType(0);
          redirectionUrl = consentUrl;
          break;
        case Flows.oneClick:
          console.log("2");
          setFlowType(1);
          redirectionUrl = redirectUrl;
          break;
        case Flows.twoClick:
          console.log("3");
          setFlowType(2);
          redirectionUrl = redirectUrl;
          break;
        case Flows.otp:
          console.log("4");
          setFlowType(5);
          setMsisdn(heNumber);
          heMsisdn = heNumber;
          break;
        default:
          console.log("5");
          setFlowType(0);
          break;
      }
    } catch (error) {
      console.log("error :; ", error);
      setFlowType(-1);
    }
  };
  const handleCheckFlowApiErrors = (
    status: number | null,
    consentUrl: string | null,
    flow: string | null
  ): boolean => {
    console.log("status :::: ", status);
    if (status !== null) {
      // This functions checks the status codes received from eligibility API and check wether it is error or not.
      if (status === 128) {
        setErrorMsg(AppMessages.invalidNumberError);
        return true;
      } else if (
        status === 204 &&
        consentUrl === null &&
        flow?.toLowerCase() !== Flows.otp
      ) {
        setErrorMsg(AppMessages.wifiError);
        return true;
      } else if (status === 400) {
        setErrorMsg(AppMessages.configurationError);
        return true;
      } else {
        return false;
      }
    } else {
      setErrorMsg(AppMessages.unexpectedError);
      return true;
    }
  };

  const onSubscribePressed = (): void => {
    try {
      // This function is called when user clicks on subscribe button and on the base of flowType futher fuctionality will be performed
      if (flowType === 0) {
        // consent flow
        console.log("Navigated to Page : ", redirectionUrl);
        if (redirectionUrl) {
          window.location.replace(redirectionUrl);
        } else {
          setErrorMsg(AppMessages.unexpectedError);
        }
      } else if (flowType === 1) {
        // one click flow
        console.log("Subscribe API Called");
        // pass null for one click
        subscribeUser(null);
      } else if (flowType === 2) {
        // two click flow
        // it will change the button text from Subscribe to Confirm
        setFlowType(3);
        setSubscribeBtnText("Confirm");
        console.log("flowType");
      } else if (flowType === 3) {
        // two click flow
        console.log("Subscribe API Called");
        // pass null for two click
        subscribeUser(null);
      } else if (flowType === 4) {
        // already subscribed
        console.log("Navigated to redirection");
        if (redirectionUrl) {
          window.location.replace(redirectionUrl);
        }
      }
    } catch (error) {}
  };

  const onChangeMsisdn = (value: string): void => {
    // This function updates the state in input field of msisdn
    try {
      console.log("value :: ", value);
      setMsisdn(value);
    } catch (error) {}
  };

  const onChangeOtp = (value: string): void => {
    // This function updates the state in input field of otp
    try {
      console.log("value otp:: ", value);
      setOtp(value);
    } catch (error) {}
  };

  const onSendOtp = async () => {
    // This function is called when user clicks on send OTP button and send-otp API will be called
    try {
      setIsLoading(true);
      setErrorMsg("");
      // if msisdn exist in eligibility API then send null in msisdn paremeter for send-otp API
      let response = await sendOtpService(sessionId, heMsisdn ? null : msisdn);
      let status = response.code;

      if (status === "0" && response.pin) {
        setOtp(response.pin);
        setIsOtp(true);
      } else {
        let error = getErrorMessage(parseInt(status as string));
        setErrorMsg(error);
      }
    } catch (error) {
      setErrorMsg(AppMessages.unexpectedError);
    } finally {
      setIsLoading(false);
    }
  };

  const onVerifyOtp = (): void => {
    // This function is called when user clicks on verify/subscribe button and subscribe API will be called
    try {
      setErrorMsg("");
      subscribeUser(otp);
    } catch (error) {
      setErrorMsg(AppMessages.unexpectedError);
    }
  };

  const subscribeUser = async (otp: string | null) => {
    // This function will be used to call subscribe API.This function will be used for one/two click and otp flow
    // pass otp as null for one and two click flow in this function
    try {
      setIsLoading(true);
      let response: ISubscribeApi = await subscribeUserService(sessionId, otp);
      console.log("response :: ", response);
      if (response.activationUrl) {
        // if redirectUrl no need to check code, BTW 0 is success
        window.location.replace(response.activationUrl);
        return;
      }
      let status = response.code;
      if (status === "5" || status === "6" || status === "13") {
        window.location.replace(activationUrl);
      } else {
        let error = getErrorMessage(parseInt(status as string));
        setErrorMsg(error);
      }
    } catch (error) {
      let _error = getErrorMessage(10000);
      setErrorMsg(_error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Landing
      flowType={flowType}
      price={price}
      currency={currency}
      msisdn={msisdn}
      otp={otp}
      isOtp={isOtp}
      onSubscribePressed={onSubscribePressed}
      onChangeMsisdn={onChangeMsisdn}
      onChangeOtp={onChangeOtp}
      onSendOtp={onSendOtp}
      onVerifyOtp={onVerifyOtp}
      payload={payload}
      subscribeBtnText={subscribeBtnText}
      errorMsg={errorMsg}
      description={description}
      isLoading={isLoading}
    />
  );
};

export default LandingScreen;
