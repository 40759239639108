import AppMessages from "../constants/AppMessages";

export const generateClickId = (): number => {
  return Math.floor(Math.random() * 1e6);
};

export const getErrorMessage = (status: number | null) => {
  switch (status) {
    case 511:
      return AppMessages.connectivityError;
    case 307:
      return AppMessages.paymentFailedError;
    case 311:
      return AppMessages.insufficientBalanceError;
    case 1004:
      return AppMessages.invalidCodeError;
    case 229:
      return AppMessages.invalidPinCodeError;
    case 524:
      return AppMessages.connectivityError;
    case 128:
      return AppMessages.invalidNumberError;

    default:
      return AppMessages.unexpectedError;
  }
};

export const formatPrice = (price: number | null) => {
  if (price) {
    let priceStr = price.toString();
    let integerPart = priceStr.slice(0, -2);
    let fractionalPart = priceStr.slice(-2);

    return fractionalPart === "00"
      ? integerPart
      : `${integerPart}.${fractionalPart}`;
  } else {
    return "";
  }
};
