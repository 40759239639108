import ApiNames from "../constants/ApiNames";
import { locale, productId, utmSource } from "../constants/Config";
import {
  ICheckFlowTypeApi,
  ISendOtpApi,
  ISubscribeApi,
} from "../interfaces/ApiInterfaces";
import { generateClickId } from "../utils/CommonFunctions";
import { getRequest, postRequest } from "./NetworkService";

export const checkFlowService = async () => {
  // This API will be used to decide the flow of app and it will also return some addition that will also be used in app
  const apiParameters: string = `${
    ApiNames.checkFlowType
  }/${productId}?utm_source=${utmSource}&click_id=${generateClickId()}&locale=${locale}`;

  let apiResponse = await getRequest(apiParameters);
  let response: ICheckFlowTypeApi = apiResponse.data;
  return response;
};

export const subscribeUserService = async (
  sessionId: string | null,
  otp: string | null
) => {
  // This API will be used to subscribe the user.
  // pin parameter will not be pass in case of one/two click
  const endPoint: string = `${ApiNames.subscribe}/${productId}`;
  let apiParameters = {};
  if (otp) {
    apiParameters = {
      session_id: sessionId,
      pin: otp,
    };
  } else {
    apiParameters = {
      sessionId: sessionId,
    };
  }

  let apiResponse = await postRequest(endPoint, apiParameters);
  let response: ISubscribeApi = apiResponse.data;
  return response;
};

export const sendOtpService = async (
  sessionId: string | null,
  msisdn: string | null
) => {
  // This API will be used to send OTP to the user.
  // msisdn parameter value will be null if msisdn exist in eligibility API
  const endPoint: string = `${ApiNames.sendOtp}/${productId}`;

  let apiParameters = {
    sessionId: sessionId,
    msisdn: msisdn,
  };

  let apiResponse = await postRequest(endPoint, apiParameters);
  let response: ISendOtpApi = apiResponse.data;
  return response;
};
