import { apiBaseUrl, postHeaders } from "../constants/Config";
import axios, { AxiosResponse } from "axios";

export const getRequest = async (
  apiParameters: string
): Promise<AxiosResponse<any, any>> => {
  let response = await axios({
    url: apiBaseUrl + apiParameters,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const postRequest = async (
  endPoint: string,
  apiParameters: {}
): Promise<AxiosResponse<any, any>> => {
  let response = await axios({
    url: apiBaseUrl + endPoint,
    method: "post",
    headers: postHeaders,
    data: apiParameters,
  });

  return response;
};
