const AppMessages = {
  alreadySubscribed:
    "You are already subscribed. Please press the login button to access the service.",
  consent:
    "I agree to weekly recurring charges deduction from my mobile balance until Unsubscription.",
  invalidNumberError: "Access denied due to invalid phone number.",
  invalidCodeError: "Invalid code. Try again please.",
  invalidPinCodeError:
    "Oops! We couldn't send the pin code. Please check your connection and try again later.",
  wifiError: "Service unavailable over WiFi. Please switch to Mobile Data.",
  configurationError:
    "Configuration issue detected: Product not found. Please contact support.",
  unexpectedError: "An unexpected error occurred. Please contact support.",
  connectivityError:
    "Oops! There's a connectivity issue with our server. Please try again later.",
  paymentFailedError: "Oops! Payment failed. Please try again later.",
  insufficientBalanceError:
    "Payment Unsuccessful: Insufficient funds for subscription. Please check your account and try again later.",
};

export default AppMessages;
