import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingScreen from "./screens/LandingScreen";
import TermsScreen from "./screens/TermsScreen";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import { gtmId } from "./constants/Config";

function App() {
  useEffect(() => {
    initializeGtm();
  }, []);
  const initializeGtm = () => {
    try {
      const tagManagerArgs = {
        gtmId: gtmId,
      };
      TagManager.initialize(tagManagerArgs);
    } catch (error) {}
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Navigate to={"/landing"} />} />
          <Route path="/landing" element={<LandingScreen />} />
          <Route path="/terms" element={<TermsScreen />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
