import React from "react";
import "../styles/Landing.css";
import logo from "../assets/images/logo.webp";
import maintext from "../assets/images/main-text.webp";
import mainimg from "../assets/images/main-img.webp";
import "../styles/Landing.css";

interface IProps {
  flowType: number;
  price: string | null;
  currency: string | null;
  msisdn: string | null;
  otp: string | null;
  isOtp: boolean;
  onSubscribePressed: () => void;
  onChangeMsisdn: (value: string) => void;
  onChangeOtp: (value: string) => void;
  onSendOtp: () => void;
  onVerifyOtp: () => void;
  payload: string | null;
  subscribeBtnText: string;
  errorMsg: string;
  description: string;
  isLoading: boolean;
}

const Landing = (props: IProps) => {
  return (
    <div className="landing-bg">
      <div className="subscrption-content">
        <div className="logo">
          <img src={logo} width={136} height={33} alt="Logo" />
        </div>

        <div className="main-text">
          <img src={maintext} width={375} height={159} alt="Main image" />
        </div>

        <div className="main-img">
          <img src={mainimg} width={375} height={309} alt="Player Image" />
        </div>
        {props.flowType !== -1 && (
          <>
            {props.currency && props.price && (
              <div className="p-text">
                <span className="spone">1 DAY FREE</span> THEN ONLY <br></br>
                <span className="sptwo">
                  {props.currency}.{props.price}/WEEK
                </span>
                (INCL OF TAX)
              </div>
            )}
            {props.errorMsg && <p className="error">{props.errorMsg}</p>}

            {props.flowType < 5 && (
              <div className="button">
                {/* <button className="disable-btn p-button">Confirm</button> */}

                <button
                  className="p-button"
                  onClick={() => {
                    props.onSubscribePressed();
                  }}
                >
                  {props.subscribeBtnText}
                </button>
              </div>
            )}
            {props.flowType === 5 && <></>}

            <div className="sub-text">{props.description}</div>

            <a className="term" href="terms">
              Terms & Conditions
            </a>
          </>
        )}
      </div>
      <div id="payload-container"></div>
    </div>
  );
};

export default Landing;
